var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{'row': _vm.isInline}},[(_vm.title)?_c('label',{staticClass:"col-form-label",class:{
    'col-lg-4 col-md-4 col-sm-12': _vm.isInline,
    'col-form-label-sm': _vm.size.toString() === 'sm',
    'col-form-label-lg': _vm.size.toString() === 'lg',
  },attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.title)),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.helperText)?_c('info-tooltip',{attrs:{"helper-text":_vm.helperText}}):_vm._e()],1):_vm._e(),_c('input',{ref:"file",staticClass:"d-none",class:{'col-lg-8 col-md-8 col-sm-12': _vm.isInline,
                  'form-control-sm': _vm.size.toString() === 'sm',
                  'form-control-lg': _vm.size.toString() === 'lg',
                 },attrs:{"type":"file","name":_vm.name,"disabled":false,"accept":_vm.accept},on:{"change":_vm.onChangeFile}}),_c('button',{staticClass:"btn btn-success",attrs:{"type":"reset"},on:{"click":function($event){return _vm.$refs.file.click()}}},[_vm._v(" "+_vm._s(_vm.$t("general.new_photo")))])])
}
var staticRenderFns = []

export { render, staticRenderFns }