<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <div class="row justify-content-start items-baseline w-100 mb-2">
        <span
            @click="$router.go(-1)"
            class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2">
          <i class="fa fa-chevron-left"></i>
        </span>
            <h3 class="d-inline-block" id="page-title">{{ $t('general.user').toUpperCase() }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box :back="true" class="px-5 py-4">
        <template v-slot:preview>
          <div class="row w-100">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('users.user_name')}`"
                  :model.sync="itemProxy.name"
                  :is-valid=" itemProxy.name != null && itemProxy.name.length !== 0 ? true : null"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('users.user_title')}`"
                  :model.sync="itemProxy.title"
                  :is-valid=" itemProxy.title != null && itemProxy.title.length !== 0 ? true : null"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('users.user_email')}`"
                  :model.sync="itemProxy.email"
                  :is-valid="isEmail(itemProxy.email)"
              ></text-input>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('users.phone_number')}`"
                  :model.sync="itemProxy.phone_number"
                  :is-valid="phoneNumberValidate"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('users.user_password')}`"
                  :model.sync="itemProxy.password"
                  type="text"
                  :is-valid=" itemProxy.password != null && itemProxy.password.length !== 0 ? true : null"
              ></text-input>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :show-invalid="false"
                  :options="companies"
                  :model.sync="itemProxy.company_id"
                  :title="$t('users.user_company')"
                  name="company_id"
                  :max="1"
                  :placeholder="$t('general.please_select_option')"
                  :not-list="true"
                  :is-inline="true"
              ></custom-multi-select>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :model.sync="itemProxy.roles"
                  :options="roles"
                  :title="$t('users.user_roles')"
                  :itemPerRow="1"
                  name="roles"
                  :is-inline="true"
              >
              </custom-multi-select>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :show-invalid="false"
                  :options="logoUsers"
                  :model.sync="itemProxy.logo_user_id"
                  :title="$t('users.logo_users')"
                  :placeholder="$t('general.please_select')"
                  name="logoUsers"
                  :max="1"
                  :not-list="true"
                  :is-inline="true"
              >
              </custom-multi-select>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :show-invalid="false"

                  :model.sync="itemProxy.supplier_companies"
                  :options="supplierCompanies"
                  :title="$t('users.supplier_companies')"
                  :itemPerRow="1"
                  name="supplierCompanies"
                  :is-inline="true"
              >
              </custom-multi-select>
            </div>
          </div>
          <div class="row w-100 justify-content-center mx-0 px-0">
            <img :src="itemProxy.avatar_file" alt="" id="user-image-holder" class="w-60px my-5">
          </div>
          <div class="row w-100 justify-content-center mx-0 px-0">
            <FileInputChange
                :is-inline="true"
                :title="null"
                name="avatar_file"
                :required="false"
                :model.sync="itemProxy.avatar"
                @fileChange="onProfileImageChange"
                disabled=""
            >
            </FileInputChange>
          </div>
          <div class="d-flex justify-content-center">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdate"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToSubmit"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import baseModule, {
  BASE_URL,
  ERROR,
  LOADING,
  MODULE_NAME,
  SUCCESS,
  ITEM,
  SET_ITEM,
  GET_ITEM,
  UPDATE_ITEM,
  CREATE_ITEM,
  LOGO_ITEMS,
  GET_LOGO_ITEMS
} from "@/core/services/store/user/user.module";

import companyModule, {
  MODULE_NAME as MODULE_NAME_COMPANY,
  GET_ITEMS as GET_ITEMS_COMPANY,
  ITEMS as ITEMS_COMPANY,
} from "@/core/services/store/company/company.module";

import supplierCompanyModule, {
  MODULE_NAME as MODULE_NAME_SUPPLIER_COMPANY,
  GET_ITEMS as GET_ITEMS_SUPPLIER_COMPANY,
  ITEMS as ITEMS_SUPPLIER_COMPANY,
} from "@/core/services/store/supplier-company/supplier.company.module";

import roleModule, {
  MODULE_NAME as MODULE_NAME_ROLE,
  GET_ITEMS as GET_ITEMS_ROLE,
  ITEMS as ITEMS_ROLE,
} from "@/core/services/store/role/role.module";

import TextInput from "@/assets/components/inputs/TextInput";
import FileInput from "@/assets/components/inputs/FileInput";
import FileInputChange from "@/assets/components/inputs/FileInputChange";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import store from "@/core/services/store";
import router from "@/router";

const _MODULE_NAME = MODULE_NAME;
const _MODULE_NAME_COMPANY = MODULE_NAME_COMPANY;
const _MODULE_NAME_SUPPLIER_COMPANY = MODULE_NAME_SUPPLIER_COMPANY;
const _MODULE_NAME_ROLE = MODULE_NAME_ROLE;

export default {
  name: "UserCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    DashboardBox,
    FileInput,
    FileInputChange,
    SelectInput,
    CustomMultiSelect
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(_MODULE_NAME, baseModule)
    registerStoreModule(_MODULE_NAME_COMPANY, companyModule)
    registerStoreModule(_MODULE_NAME_SUPPLIER_COMPANY, supplierCompanyModule)
    registerStoreModule(_MODULE_NAME_ROLE, roleModule)
  },
  data() {
    return {
      id: this.$route.params.id,
      indexRouteName: 'management.users.index',
      itemProxy: {
        id: -1,
        name: null,
        email: null,
        phone_number: null,
        logo_user_id: null,
        company_id: null,
        password: null,
        avatar: null,
        avatar_file: null,
        roles: [],
        supplier_companies: []
      },
    };
  },
  computed: {
    phoneNumberValidate() {
      return (/^([0-9]{10})$/.test(this.itemProxy.phone_number));
    },
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    item() {
      return store.getters[_MODULE_NAME + '/' + ITEM];
    },
    logoUsers() {
      let items = store.getters[_MODULE_NAME + '/' + LOGO_ITEMS];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'usrID', 'usrName');
      }
      return {};
    },
    companies() {
      let items = store.getters[_MODULE_NAME_COMPANY + '/' + ITEMS_COMPANY];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'id', 'name');
      }
      return {};
    },
    supplierCompaniesRaw() {
     return store.getters[_MODULE_NAME_SUPPLIER_COMPANY + '/' + ITEMS_SUPPLIER_COMPANY];
    },
    supplierCompanies() {
      let items = store.getters[_MODULE_NAME_SUPPLIER_COMPANY + '/' + ITEMS_SUPPLIER_COMPANY];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'id', 'name');
      }
      return {};
    },
    rolesRaw() {
      return store.getters[_MODULE_NAME_ROLE + '/' + ITEMS_ROLE];
    },
    roles() {
      let items = store.getters[_MODULE_NAME_ROLE + '/' + ITEMS_ROLE];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'id', 'name');
      }
      return {};
    },
    isValidToSubmit(){
      if(this.itemProxy.name === null || this.itemProxy.name === '') return false;
      if(this.itemProxy.title === null || this.itemProxy.title === '') return false;
      if(!this.phoneNumberValidate) return false;
      if(this.itemProxy.email === null || this.itemProxy.email === '' || !this.isEmail(this.itemProxy.email)) return false;
      if(this.id !== null) {
        if(this.itemProxy.avatar_file === null) return false;
      }
      return true;
    },

  },
  methods: {
    setUserItem() {
      let userItem = this.item;
      userItem = {...userItem, avatar: null};
      let userRoles = userItem.roles;
      let userSupplierCompanies = userItem.supplier_companies;
      userItem.roles = [];
      userItem.supplier_companies = [];

      if (userItem.logo_user_id) {
        userItem.logo_user_id = userItem.logo_user_id.toString();
      }

      if (!_.isEmpty(this.rolesRaw)) {
        let roles =  _.map(this.rolesRaw, 'id');
        roles.forEach(item => {
          if (userRoles.find(subItem => subItem.id === item)) {
            userItem.roles.push(item.toString());
          }
        })
      }

      if (!_.isEmpty(this.supplierCompaniesRaw)) {
        let supplier_companies =  _.map(this.supplierCompaniesRaw, 'id');
        supplier_companies.forEach(item => {
          if (userSupplierCompanies.find(subItem => subItem.id === item)) {
            userItem.supplier_companies.push(item.toString());
          }
        })
      }

      this.itemProxy = userItem;
    },
    onProfileImageChange(payload) {
      this.itemProxy.avatar_file = payload.blob;
    },
    createOrUpdate() {
      let form = new FormData();
      form.append('name', this.itemProxy.name);
      form.append('email', this.itemProxy.email);
      form.append('phone_number', this.itemProxy.phone_number);
      form.append('title', this.itemProxy.title);
      form.append('supplier_companies', this.itemProxy.supplier_companies);

      if (this.itemProxy.company_id) {
        form.append('company_id', this.itemProxy.company_id);
      }

      if (this.itemProxy.logo_user_id) {
        form.append('logo_user_id', this.itemProxy.logo_user_id);
      }

      form.append('roles', this.itemProxy.roles);

      if(this.itemProxy.password){
        form.append('password', this.itemProxy.password);
        form.append('password_confirmation', this.itemProxy.password);
      }
      if(this.avatar_file !== null) {
        form.append('avatar', this.itemProxy.avatar);
      }

      let payload = {
        url: this.id == null ? BASE_URL : BASE_URL + '/' + this.id,
        id: this.id,
        contents: form
      }
      this.$store.dispatch( _MODULE_NAME + '/'+ CREATE_ITEM, payload);
    },
    getCompanies() {
      this.$store.dispatch(_MODULE_NAME_COMPANY + '/' + GET_ITEMS_COMPANY, {
        filters: {}
      });
    },
    getSupplierCompanies() {
      this.$store.dispatch(_MODULE_NAME_SUPPLIER_COMPANY + '/' + GET_ITEMS_SUPPLIER_COMPANY, {
        filters: {}
      });
    },
    getRoles() {
      this.$store.dispatch(_MODULE_NAME_ROLE + '/' + GET_ITEMS_ROLE, {
        filters: {}
      });
    },
    getLogoUsers() {
      this.$store.dispatch(_MODULE_NAME + '/' + GET_LOGO_ITEMS, {
        filters: {}
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("general.user"), route: this.indexRouteName},
      {title: this.$t(this.id ? "general.user_edit" : "general.user_create")},
    ]);

    if (_.isEmpty(this.companies)) {
      this.getCompanies();
    }

    if (_.isEmpty(this.supplierCompanies)) {
      this.getSupplierCompanies();
    }

    if (_.isEmpty(this.roles)) {
      this.getRoles();
    }

    if (_.isEmpty(this.logoUsers)) {
      this.getLogoUsers();
    }

    if(!this.id){
      this.$store.commit(_MODULE_NAME + '/'+ SET_ITEM, this.tempItem);
    } else {
      let payload = {
        url: BASE_URL + '/' +this.id,
        id: this.id,
      }
      this.$store.dispatch(_MODULE_NAME + '/'+ GET_ITEM, payload)
    }
  },
  watch: {
    item(value) {
      if (value) {
        this.setUserItem();
      }
    },
    supplierCompanies() {
      this.setUserItem();
    },
    roles() {
      this.setUserItem();
    },
    logoUsers() {
      this.setUserItem();
    },
    companies() {
      this.setUserItem();
    },
    success(value) {
      if (value) {
        let message = this.id == null ? this.$t('general.successfully_created') : this.$t('general.successfully_updated');
        let self = this;
        setTimeout(() => {
          self.sweetAlertSuccess(message).then((result) => {
            if (result) {
              router.push({name: this.indexRouteName});
            }
          })
        }, 500);
      }
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
